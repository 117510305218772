@keyframes draw-line {
  0% {
    stroke-dashoffset: 150%;
  }
  100% {
    stroke-dashoffset: 0%;
  }
}

.PathToPoint {
  position: relative;
  width: 420px;
  height: auto;
  display: flex;
  margin-top: 100px;
  .point1 {
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    transform: translateY(-40px);
    text-wrap: nowrap;
    color: var(--blue1);
    background-color: inherit;
    width: auto;
    height: auto;
    padding: 23px;
    border-radius: 50%;
    text-wrap: wrap;
    max-width: 200px;

    // &::after {
    //   content: "";
    //   width: 8px;
    //   height: 8px;
    //   background-color: var(--blue1);
    //   position: absolute;
    //   top: 30px;
    //   left: 0px;
    //   border-radius: 100%;
    // }
  }
  .point2 {
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    // bottom: 12px;
    // right: -50px;
    color: var(--blue1);
    transform: translateY(-40px);
    max-width: 200px;
    background-color: #eff2f1;
    height: auto;
    padding: 23px;
    border-radius: 50%;
    z-index: 20;
    text-wrap: wrap;

    // &::after {
    //   content: "";
    //   width: 8px;
    //   height: 8px;
    //   background-color: var(--blue1);
    //   position: absolute;
    //   top: 30px;
    //   left: 0px;
    //   border-radius: 100%;
    // }
  }
  .randomLine {
    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      transition: all 0.15s ease;
      polyline {
        transition: all 0.15s ease;
      }
    }
  }
  .randomLineActive {
    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      transition: all 0.15s ease;
      z-index: 1;
      polyline {
        transition: all 0.15s ease;
        stroke-dasharray: 150%;
        stroke-dashoffset: 100%;
        animation: draw-line 2s ease-in-out forwards;
      }
    }
  }
}
