@import "./../../styles/mixins.scss";
@import "./../../styles/colors.scss";
.Input {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #adadad;
  background-color: inherit;
  width: 949px;
  @media (max-width: 1360px) {
    width: 635px;
  }
  .input {
    @include Input();
  }

  img {
    width: 20px;
    height: 20px;
    margin: auto 10px;
    cursor: pointer;
  }
}
