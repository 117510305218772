@import "./../../styles/mixins.scss";
.PersonalArchive {
  .BgImgCardHuman {
    position: absolute;
    right: 32px;
    top: 105px;
    width: 448px !important;
    height: 67px !important;
  }
  .container {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 120px;
    flex-direction: column;
  }
  .pageArrow {
    position: absolute;
    top: 120px;
    left: 150px;
    width: 84px;
    height: 20px;
    cursor: pointer;
    &:hover {
      svg * {
        fill: var(--blue1);
        transition: all 0.15s linear;
      }
      transition: all 0.15s linear;
    }
    &:active {
      svg * {
        fill: var(--blue3);
        transition: all 0.15s linear;
      }
    }
  }
  .slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 70vw;
    height: 70vh;
    margin: 0 auto;
    .containerFotos {
      position: relative;
      width: 50vw;
      height: 80vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .imgosn {
      // width: 500px;
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 70%;
      object-fit: contain;
      // width: 1203px;
      // height: 904px;
      // height: 70vh;
      // object-fit: cover;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .imgosn2 {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 90%;
      object-fit: contain;
      top: 140px;
    }
    .imgblur {
      filter: blur(12px);
      position: absolute;
      width: 105%;
      height: 68%;
      object-fit: cover;
      box-sizing: border-box;
      z-index: 0;
      opacity: 0.5;
    }
    button {
      background-color: inherit;
      border: none;
      height: 40vw;
      margin: 0 40px;
      cursor: pointer;
      &:hover {
        background-color: #d6d6d648;
        svg * {
          stroke: var(--blue1);
          transition: all 0.15s linear;
        }
        transition: all 0.15s linear;
      }
      &:active {
        svg * {
          stroke: var(--blue3);
          transition: all 0.15s linear;
        }
      }
      svg {
        margin: 20px;
        width: 29px;
        height: 100%;
        box-sizing: border-box;
      }
      .rigth {
        transform: rotate(180deg);
      }
    }
  }
  .styderPoints {
    margin-top: 20px;
    ul {
      @include Ul();
      li {
        cursor: pointer;

        width: 12px;
        height: 12px;
        background-color: var(--GrayTextLight);
        margin: 10px;
      }
      .active {
        background-color: var(--blue3);
      }
    }
  }
  .imgs {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    margin-top: 3vw;
    overflow-y: auto;
    @include scrollStyle(4px, 12px);
    ul {
      @include Ul();
      margin: 0 auto;

      li {
        cursor: pointer;
        position: relative;
        margin: 16px;
        img {
          width: 277px;
          height: 206px;
          filter: brightness(0.7);
          object-fit: cover;
        }
        .lupa {
          z-index: 2;
          filter: brightness(1);
          position: absolute;
          width: 44px;
          height: 46px;
          top: 42%;
          left: 42%;
        }
      }
    }
  }
  .blakBoxTypePhotoContainer {
    display: flex;
    justify-content: center;
    height: 5vw;
    position: relative;
  }
  .blakBoxTypePhoto {
    background-color: #83818148;
    position: absolute;
    bottom: 0px;
    z-index: 1000;
    width: 15vw;
    height: 3vw;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    justify-content: start;
    align-items: center;
    p {
      margin: 0 auto;
      font-size: 1vw;
    }
  }
}
