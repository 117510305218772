@import "./../../styles/mixins.scss";
@import "./../../styles/colors.scss";

.Header {
  // width: 100%;
  // position: fixed;
  // z-index: 10;
  // background-color: #eff2f1;
  // background-color: blur(10px);
  // top: 0;
  // left: 0;
z-index: 10000;
  background:rgba(243, 240, 250, .32);    
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  width: 100vw;
  height: 100px;
  backdrop-filter: blur(6rem);
  .headerInner {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;
  }

  ul {
    @include Ul;
    margin: 0 auto;
    font-size: 22px;
    font-weight: 400;
    li a {
      outline: none;
      text-decoration: none;
      color: #000;
    }
    li {
      margin-right: 77px;
      cursor: pointer;
    }
    li:last-child {
      margin: 0;
    }
    li a:hover {
      color: var(--blue1);
      transition: color 0.15s ease;
    }
    li a:active {
      color: var(--blue3);
    }
  }
  .active {
    a {
      color: var(--blue1);
    }
  }
}
