.Logo {
  left: 48px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin-left: 48px;
  .logoText {
    display: flex;
    flex-direction: column;
    padding-left: 16px;

    span:nth-child(1) {
      color: var(--blue1);
      font-size: 22px;
      font-weight: 500;
    }
    span:nth-child(2) {
      color: var(--blue1);
      font-size: 22px;
      font-weight: 400;
      line-height: 29.33px;
      letter-spacing: 0.16em;
    }
  }
}
