@import "./../../styles/mixins.scss";
@import "./../../styles/colors.scss";

.CreateHuman {
  h1 {
    margin-top: 64px;
  }
  form {
    .blockInput {
      display: flex;
      width: 100%;
      .blockFormFirst {
        display: block;
        width: 741px;
        margin-right: 102px;
        input {
          border: none;
          @include Input();
          border-bottom: 1px solid var(--border);
        }
        .biginp {
          padding-bottom: 34px;
        }
      }
      .address {
        display: block;
        position: relative;
        input {
          padding-bottom: 1vw;
        }
        ul {
          width: 100%;
          position: absolute;
          display: flex;
          flex-direction: column;
          z-index: 100;
          left: -40px;
          top: 80px;
          overflow-y: auto;
          height: 40vh;
          @include scrollStyle();
          &::-webkit-scrollbar-track {
            background: #fff; /* цвет дорожки */
          }
          li {
            display: flex;
          }
          button {
            padding: 10px;
            font-size: 18px;
            background-color: #fff;
            border: none;
            cursor: pointer;
            text-align: left;
            &:hover {
              background-color: var(--blue1);
              color: #fff;
              transition: all 0.15s ease;
            }
          }
        }
      }
      .blockFormSecond {
        display: block;
        width: 741px;
        input {
          border: none;
          @include Input();
          border-bottom: 1px solid var(--border);
        }
      }

      input {
        margin: 25px 0px;
      }
      .SubmitButton {
        // width: 949px;
        position: relative;
        top: -30px;
        margin-bottom: 24px;
        input {
          width: 100%;
          @include Button();
          font-size: 24px !important;
        }
      }
      .Button {
        display: flex;

        a {
          text-decoration: none;
        }
        button {
          margin-top: -22px;
          @include Button();
          width: 741px !important;
          font-size: 24px !important;
        }
      }
      .SelectFile {
        display: flex;
        position: relative;
        top: -90px;
        cursor: pointer;
        width: 741px;
        justify-content: end;
        height: 61px;
        input {
          pointer-events: none;
        }
        .SelectFile__img {
          display: flex;
          width: 120px;
          justify-content: center;
          align-items: center;
          color: var(--border);
          margin-right: 12px;
          img {
            margin-left: 15px;
            width: 25px;
            height: 50px;
            cursor: pointer;
            transition: 0.1s linear all;
            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
      .DataSave,
      .DataNotSave {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 112px;
        width: 737px;
        position: relative;
        top: 20px;
        img {
          position: absolute;
          top: 10px;
          right: 10px;
          transition: 0.1s linear all;
          &:hover {
            opacity: 0.6;
            cursor: pointer;
          }
        }
      }
      .DataSave {
        border: 2px solid var(--GeenColor);
        color: var(--GeenColor);
        margin-bottom: 32px;
      }
      .DataNotSave {
        border: 2px solid var(--RedColor);
        color: var(--RedColor);
        margin-bottom: 32px;
      }
      .SelectFile__multiple {
        display: flex;
        justify-content: space-between;
        position: relative;
        top: -50px;
        border-bottom: 1px solid var(--border);
        padding: 0 5px;
        background-color: inherit;
        .SelectFile__multiple__containerNameFile {
          display: flex;
          align-items: center;
          img {
            position: relative;
            top: 3px;
            margin-left: 10px;
            width: 12px;
            height: 12px;
            transition: 0.1s linear all;
            &:hover {
              cursor: pointer;
              opacity: 0.6;
            }
          }
        }
        .SelectFile__multiple__img {
          display: flex;
          color: var(--border);

          position: relative;
          top: 8px;
          right: 15px;
          transition: 0.1s linear all;
          &:hover {
            cursor: pointer;
            opacity: 0.6;
          }
          img {
            margin-left: 15px;
            width: 25px;
            height: 50px;
            cursor: pointer;
            transition: 0.1s linear all;
            &:hover {
              opacity: 0.6;
            }
          }
        }
        .SelectFile__multiple__textNotData {
          color: #afbbbd;
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
    input {
      font-size: 1vw !important;
    }
  }
  .archive {
    display: flex;
    justify-content: start;
    align-items: center;
    font-family: "Playfair";
    a {
      font-size: 26px;
      font-weight: 500;
      line-height: 52px;
      text-align: left;
      text-decoration: none;
      color: #828282;
      transition: 0.1s linear all;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  .errorMessage {
    color: var(--RedColor);
    margin-top: -15px;
    margin-bottom: 0px;
  }
}
@keyframes boxShadowFade {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 10px var(--RedColor); // Adjust the shadow as needed
  }
  100% {
    box-shadow: none;
  }
}

.inputError {
  border-bottom: 1px solid var(--RedColor) !important; // Change border color to red
  color: var(--RedColor) !important; // Change text color to red
  animation: boxShadowFade 1.5s ease-in-out forwards; // Apply the animation
  &::placeholder {
    color: var(--RedColor) !important; // Change placeholder color to red
  }
}
