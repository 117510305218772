@import "./../../styles/mixins.scss";
@import "./../../styles/colors.scss";

.CardArchiveNotData {
  .CardArchiveNotDataInner {
    display: flex;

    .blockAdd {
      width: 277px;
      height: 486px;
      margin: 15px;
      border: 1px #000 dashed;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.1s linear all;

      &:hover {
        border: 1px var(--blue1) dashed;
        color: #fff;
        transition: 0.1s linear all;

        svg * {
          fill: var(--blue1);
        }
      }
    }

    .TypeAdd {
      display: flex;
      margin-top: 15px;
      .ArrowLeft {
        height: 58px;
        width: 38px;
        background-color: #fff;
        box-shadow: 0px 0px 10.7px 0px #00000026;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 32px;
        transition: 0.1s linear all;
        cursor: pointer;

        &:hover {
          svg * {
            fill: var(--blue1);
          }
        }
      }

      .TypeAddList {
        background-color: #fff;
        box-shadow: 0px 0px 10.7px 0px #00000026;
        margin-left: 16px;
        width: 809px;
        height: 488px;
        padding: 2px 48px;
        position: relative;
        box-sizing: border-box;

        p {
          font-family: "Playfair";
          font-size: 28px;
          font-weight: 500;
          margin: 0;
          text-align: left;
        }

        button {
          position: absolute;
          bottom: 12px;
          left: 48px;
          padding: 6px !important;
          @include Button();
          width: 710px;
        }
        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 5px;
          margin-top: 5px;

          li {
            font-size: 16px;
            margin-bottom: 4px;
            width: max-content;
            .textOption {
              position: relative;
            }

            // Custom checkbox styles
            .customCheckbox {
              position: relative;
              display: flex;
              align-items: center;
              cursor: pointer;
              img {
                position: relative;
                right: -16px;
                z-index: 100;
                top: 2px;
              }
              input[type="checkbox"] {
                display: none; // Hide the default checkbox
              }

              span::before {
                content: "";
                width: 20px;
                height: 20px;
                border: 1px solid #2d2d2d;
                background-color: white;
                display: inline-block;
                position: relative;
                margin-right: 10px;
                transition: background-color 0.2s, border-color 0.2s;
                top: 4px;
              }

              span.errorLi::before {
                border: 1px solid var(--RedColor) !important;
                animation: boxShadowFade 1.5s ease-in-out forwards; // Apply the animation
              }

              input[type="checkbox"]:checked + span::before {
                background-color: #2d2d2d !important; // Black background when checked
                border-color: #2d2d2d; // Remove border color

                // Add a white checkmark
                &::after {
                  content: "";
                  width: 10px; // Adjust size as needed
                  height: 10px; // Adjust size as needed
                  border: solid white; // White checkmark
                  border-width: 0 2px 2px 0; // Create a checkmark shape
                  position: absolute; // Position it absolutely
                  left: 5px; // Adjust position as needed
                  top: 2px; // Adjust position as needed
                  transform: rotate(45deg); // Rotate to form a checkmark
                }
              }
            }
          }
        }
        .fileUploadContainerStreat {
          display: flex;
          position: relative;
          .fileUploadContainer {
            right: -25px;
            position: relative;
            width: 200%;
            .fileUploadContainerblock {
              width: 304px;
              height: 32px;
              cursor: pointer;
              position: absolute;
            }
            .fileUpload {
              display: flex;
              width: 320px;
              height: 30px;
              border: none;
              border-bottom: 1px solid var(--border);
              font-size: 18px;
              cursor: pointer;
              background-color: inherit;
            }
            .fileUploadSecret {
              display: none;
              width: 320px;
            }
          }
          // .fileDeleteImg{
          //     position: relative;
          //     top: 14px;
          //     left: 10px;
          // }
        }
        .fileContainerStreat {
          display: flex;
          justify-content: start;
          align-items: center;
          flex-direction: row;
          .fileUploadContainerblock {
            margin-left: 10px;
          }
        }
      }
    }
  }
  .errorFile {
    position: absolute;
    right: -200px;
    top: 10px;
    font-size: 18px !important;
    color: var(--RedColor);
    animation: boxShadowFade 1.5s ease-in-out forwards; // Apply the animation
  }
}

@keyframes boxShadowFade {
  0% {
    box-shadow: none;
  }
  50% {
    box-shadow: 0 0 5px var(--RedColor); // Adjust the shadow as needed
  }
  100% {
    box-shadow: none;
  }
}
