.MapComponent {
  display: flex;
  width: 100%;
  height: calc(100% - 100px);
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 11;
  box-sizing: border-box;
}
