
@import "./../../../styles/colors.scss";
@import "./../../../styles/mixins.scss";

.AdminPanelModule{
    h1{
    &:first-child{
        margin-top: 164px;
    }
    font-family: Playfair Display;
    font-size: 34px;
    font-weight: 500;
    line-height: 42.5px;
    text-align: left;
    width: 500px;
    }
    .search__inner{
        display: flex;
        button {
            @include Button();
            width: 277px!important;
            margin-left: 32px;
        }
        input {
            display: flex;
            width: 896px;
            border: none;
        }
    }
    .title{
        margin-top: 164px!important;
    }
    .Button{
        display: flex;
        justify-content: end;
        width: 1582px!important;
        a{
            text-decoration: none;
        }
        button{
            margin-top: -22px;
            @include Button();
            width: 741px!important;
        }
    }
}