:root {
  --blue1: #5b6a99; // кнопка обычная
  --blue2: #3d4970; // кнопка навели
  --blue3: #262f4e; // кнопка нажали
  --border: #828282; // цвет обводки
  --border2: #d6d6d6;
  --textColor: #2d2d2d; // цвет текста
  --background-body: #EFF2F166;
  --GrayTextLight: #BFC4CE;
  --GeenColor:#1B7338;
  --RedColor: #9E2929;
}
