.HumanInfoComponent {
  p {
    margin: 0;
    font-family: Playfair Display;
    font-size: 22px;
    font-weight: 500;
    line-height: 44px;
    text-align: left;
    span {
      font-weight: 600;
    }
  }
}
