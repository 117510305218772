.Card {
  display: flex;
  flex-direction: column;
  width: 278px;
  height: 450px;
  border: 1px var(--border) solid;
  box-sizing: border-box;
  margin-bottom: 32px;
  margin: 10px;
  // margin: 16px 15px;
  cursor: pointer;
  &:hover {
    background-color: var(--blue1);
    color: #fff;
    transition: all 0.15s ease;
  }
  img {
    width: 246px;
    height: 314px;
    margin: 16px;
    object-fit: cover;
  }
  .cardText {
    margin: 16px;
    margin-top: 0;
    display: flex;
    flex-direction: column;

    .name {
      font-size: 22px;
      font-weight: 500;
      line-height: 25px;
      text-wrap: nowrap;
    }
    .info {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      height: 40px;
      text-wrap: nowrap;
    }
    span {
      overflow: hidden; /* Скрывает текст, выходящий за пределы блока */
      text-overflow: ellipsis; /* Добавляет многоточие в конце обрезанного текста */
    }
  }
}
