.HumanProfile {
  width: 100%;
  color: black;

  .HumanProfile__card {
    display: flex;
    img {
      width: 432px;
      height: 552px;
      margin-right: 32px;
      @media (max-width: 1850px) {
        width: 302px;
        height: 422px;
      }
    }
    .HumanImg {
      img {
        object-fit: cover;
      }
    }
    .way {
      display: flex;
      margin-left: 15%;
      @media (max-width: 1850px) {
        margin-left: 12%;
      }
    }
    margin-left: 100px;
    margin-top: 220px;
    a {
      outline: none;
      text-decoration: none;
      color: #000;
    }
    .HumanProfile__card__info {
      width: auto;
      max-width: 26%;

      @media (max-width: 1850px) {
        width: auto;
        padding-right: 10px;
        max-width: 26%;
      }

      .HumanProfile__card__info__name {
        display: flex;
        flex-direction: column;
        font-family: "Playfair Display";
        font-size: 52px;
        font-weight: 500;
        line-height: 60px;
        text-align: left;
        color: #3d4970;
        max-width: 24vw;
        overflow: hidden;

        p {
          display: inline-block;
          width: min-content;
          max-width: 100%;
          border-bottom: 2px solid #3d4970;
          margin: 0;
          transition: all 0.1s linear;
          @media (max-width: 1850px) {
            line-height: 40px;
            font-size: 32px;
          }
          // overflow-wrap: break-word;
        }

        @keyframes marquee {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(-110%);
          }
        }
        p::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
        @media (max-width: 1850px) {
          font-size: 52px;
          line-height: 70px;
        }
      }

      .nameScrollAnim {
        width: 100%;
        overflow: hidden;
        p {
          max-width: none;
          animation: marquee 10s linear infinite;
        }
      }

      .HumanProfile__card__info__archiv {
        transition: 0.1s linear all;
        span {
          font-family: "Playfair Display";
          font-size: 26px;
          font-weight: 500;
          line-height: 52px;
          text-align: left;
          color: #828282;
          border-bottom: 2px solid #828282;
          @media (max-width: 1850px) {
            font-size: 22px;
          }
        }
        &:hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
      img {
        width: 16px;
        height: 20px;
        margin-left: 8px;
        @media (max-width: 1850px) {
          transform: translateY(6px);
        }
      }
    }
  }
  .BgImgCardHuman {
    position: absolute;
    right: 0;
    top: 105px;
    width: 448px !important;
    height: 67px !important;
  }
  .pageArrow {
    position: absolute;
    top: 160px;
    left: 100px;
    width: 84px;
    height: 20px;
    cursor: pointer;
    &:hover {
      svg * {
        fill: var(--blue1);
        transition: all 0.15s linear;
      }
      transition: all 0.15s linear;
    }
    &:active {
      svg * {
        fill: var(--blue3);
        transition: all 0.15s linear;
      }
    }
  }
  .HumanProfile__info {
    margin-left: 100px;
    margin-top: 100px;
    border-left: 2px solid #3d4970;
    padding-left: 36px;
    .HumanProfile__info__link {
      color: #3d4970;
      transition: 0.1s linear all;
      width: max-content;
      border-bottom: 1px solid #3d4970;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }
}
