@import "./../../styles/mixins.scss";
@import "./../../styles/colors.scss";

.CardAdminMainBlock {
  position: relative;

  .CardAdmin {
    margin: 10px;
    display: flex;
    flex-direction: column;
    width: 278px;
    height: 450px;
    border: 1px var(--border) solid;
    box-sizing: border-box;
    margin-bottom: 32px;
    position: relative;
    justify-content: center;
    align-items: center;
    // margin: 16px 15px;
    cursor: pointer;
    &:hover {
      background-color: var(--blue1);
      color: #fff;
      transition: all 0.15s ease;
    }
    .CardAdminImg {
      display: flex;
      justify-content: center;
      img {
        width: 246px;
        height: 314px;
        margin: 16px;
        object-fit: cover;
      }
      .CarrdEdit {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: #fff;
        padding: 10px;
        transition: 0.1s linear all;

        &:hover {
          * {
            fill: #fff;
          }
          cursor: pointer;
          background-color: var(--blue1);
        }
      }
      .CarrdDelite {
        position: absolute;
        top: 10px;
        left: 10px;
        background-color: #fff;
        padding: 10px;
        transition: 0.1s linear all;
        &:hover {
          * {
            fill: #fff;
          }
          cursor: pointer;
          background-color: var(--blue1);
        }
      }
    }

    .cardText {
      margin: 16px;
      margin-top: 0;
      display: flex;
      flex-direction: column;
      width: 90%;

      .name {
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        text-wrap: nowrap;
        height: 23px;
      }
      .info {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        height: 40px;
      }
      span {
        overflow: hidden !important; /* Скрывает текст, выходящий за пределы блока */
        text-overflow: ellipsis !important; /* Добавляет многоточие в конце обрезанного текста */
      }
    }
  }
  .DeleteCard {
    position: absolute;
    background-color: #eff2f1;
    width: 247px;
    height: 250px;
    display: flex;
    justify-content: center;
    z-index: 100;
    top: 80px;
    left: 25px;
    .text {
      text-align: center;
      font-family: "Playfair Display";
      font-size: 22px;
      font-weight: 500;
      line-height: 29.33px;
      text-align: center;
    }
    .button__inner {
      display: block;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      button {
        margin: 0 auto;
        margin-top: 10px;
        @include Button();
        width: 135px;
        height: 49px;
      }
    }
  }
}
