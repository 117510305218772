@import "./../../styles/mixins.scss";
@import "./../../styles/colors.scss";

form {
  .blockInput {
    display: flex;
    // width: 56vw;
    .blockFormFirst {
      display: block;
      width: 250px;
      margin-right: 102px;
      input {
        border: none;
        @include Input();
        border-bottom: 1px solid var(--border);
        font-size: 20px;
        @media (max-width: 1460px) {
          font-size: 16px;
        }
      }
    }
    .blockFormSecond {
      display: flex;
      flex-direction: column;
      width: 597px;
      input {
        border: none;
        @include Input();
        border-bottom: 1px solid var(--border);
        font-size: 20px;
        @media (max-width: 1460px) {
          font-size: 16px;
        }
      }
      .bigInp {
        padding-bottom: 35px;
        padding-right: 140px;
        &:first-child {
          margin-top: 0px;
          position: relative;
          top: 7px;
        }
        margin-bottom: 20px;
      }
    }
    input {
      margin: 25px 0px;
    }
    .bigInp {
      margin-bottom: 8px;
    }
  }
  .SubmitButton {
    // width: 949px;
    display: flex;
    flex-direction: row;

    input {
      width: 50%;
      margin-right: 60px;
      @include Button();
      font-family: "Playfair Display";
      font-size: 1.5vw;
      font-weight: 500;
      letter-spacing: 0.63em;
      text-transform: uppercase;
    }
    .reset {
      width: 50%;
      margin-left: 12px;
      @include Button2();
      font-family: "Playfair Display";
      font-size: 1.5vw;
      font-weight: 500;
      letter-spacing: 0.33em;
      text-transform: uppercase;
    }
  }
  .blockInput {
    img {
      right: -20px !important;
    }
  }
}
