@import "./../../styles/colors.scss";
@import "./../../styles/mixins.scss";

.NoSearchResults{
    margin-top: -100px;
    .NoSearchResults__inner{
        background-image: url('./../../../public/img/Bg404.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
            //styleName: ошибка;
            font-family: Playfair Display;
            font-size: 44px;
            font-weight: 500;
            // line-height: 66px;
            text-align: left;
            color: var(--blue2);
            display: flex;
            justify-content: center;
            width: 100%;
        }   
        a{
            text-decoration: none
        }
      button{
        @include Button();
        width: 1000px;
        margin-left: 32px;
      }
    }

}