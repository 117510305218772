@import "./../../styles/colors.scss";


.ErrorPage{
    .ErrorPage__inner{
        background-image: url('./../../../public/img/Bg404.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 80%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p:first-child{
            //styleName: ошибка;
            font-family: Playfair Display;
            font-size: 44px;
            font-weight: 500;
            // line-height: 66px;
            text-align: left;
            color: var(--blue2);
            position: absolute;
            top: 200px;
        }   
        p:last-child{
            font-family: Playfair Display;
            font-size: 500px;
            font-weight: 600;
            // line-height: 666.5px;
            // letter-spacing: 0.1em;
            text-align: left;
            color: var(--GrayTextLight);
        }
    }

}