@import "./colors.scss";

@mixin Button(
  $width: 100%,
  $paddingTB: 10px,
  $paddingLR: 20px,
  $bgColor: var(--blue1),
  $textColor: #fff,
  $borderradius: 0px,
  $fs: 30px,
  $fw: 300
) {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bgColor;
  padding: $paddingTB $paddingLR;
  outline: none;
  color: $textColor;
  border-radius: $borderradius;
  border: none;
  width: $width;
  font-size: $fs;
  font-weight: $fw;

  &:hover {
    background-color: var(--blue2);
    cursor: pointer;
    transition: all 0.15s;
  }
  &:active {
    background-color: var(--blue3);
    cursor: pointer;
    transition: all 0.15s;
  }
}

@mixin Button2(
  $width: 100%,
  $paddingTB: 10px,
  $paddingLR: 20px,
  $textColor: #828282,
  $fs: 30px,
  $fw: 300
) {
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $paddingTB $paddingLR;
  outline: none;
  color: $textColor;
  border: 1px solid $textColor;
  width: $width;
  font-size: $fs;
  font-weight: $fw;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
    background-color: inherit;
    transition: all 0.15s;
  }
  &:active {
    opacity: 1;
    transition: all 0.15s;
  }
}

@mixin Input(
  $width: 100%,
  $border-radius: 0px,
  $bgColor: inherit,
  $paddingTB: 16.5px,
  $paddingLR: 10px,
  $color: #000000
) {
  width: $width;
  outline: none;
  background-color: inherit;
  color: $color;
  padding: $paddingTB $paddingLR;
  border-radius: $border-radius;
  box-sizing: border-box;
  &::placeholder {
    color: #afbbbd;
    flex-wrap: wrap;
    text-wrap: wrap;
  }
  font-size: 20px;
  font-weight: 400;
}

@mixin Ul() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@mixin scrollStyle($width: 8px, $height: 16px) {
  &::-webkit-scrollbar {
    width: $width; /* ширина scrollbar */
    height: $height;
  }
  &::-webkit-scrollbar-track {
    background: inherit; /* цвет дорожки */
  }
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
    border-radius: 5px;
    border: 3px solid #a6a6a6;
  }
}
