@import "./../../styles/mixins.scss";
@import "./../../styles/colors.scss";

.footer {
  position: relative;
  width: 100%;
  // position: absolute;
  z-index: 10;
  background-color: #2d2d2d;
  left: 0;
  bottom: 0;
  height: 287px;
  padding: 64px 48px;
  box-sizing: border-box;

  .footer_innerTop {
    display: flex;
    justify-content: space-between;
    ul {
      @include Ul;
      margin: 0 auto;
      font-size: 22px;
      font-weight: 400;
      li a {
        outline: none;
        text-decoration: none;
        color: #ffffff;
      }
      li {
        margin-right: 77px;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        color: #ffffff;
        &:hover {
          color: var(--blue1);
          transition: color 0.15s ease;
        }
      }
      li:last-child {
        margin: 0;
      }
      li a:hover {
        color: var(--blue1);
        transition: color 0.15s ease;
      }
      li a:active {
        color: var(--blue3);
      }
    }
    .active {
      a {
        color: var(--blue1);
      }
    }
    .footer__mesagers {
      img {
        transition: 0.1s linear all;
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
        margin: 0px 10px;
      }
    }
  }
  .email {
    position: absolute;
    right: 3.8vw;
    bottom: 2.7vw;
    color: #ffffff;
    font-family: "Playfair Display";
    font-size: 1vw;
    margin: 0;
    padding: 0;
    outline: none;
    text-decoration: none;
  }

  .footer_innerBottom {
    color: #ffffff;
    // padding-top: 167px;
  }
  .footer__rnf {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    padding-top: 0;
    p {
      display: flex;
      margin-top: 1vw;
      margin-left: 1vw;
    }
    .rnf {
      display: flex;
      // width: 5vw;
      height: 2vw;
      margin: 0;
      padding: 0;
    }
  }
}
