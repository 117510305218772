@import "./../../../styles/mixins.scss";

.SearchModule {
  min-height: 70vh;
  h1 {
    width: 481px;
    font-family: Playfair Display;
    font-size: 34px;
    font-weight: 500;
    line-height: 42.5px;
    text-align: left;
    width: 500px;
  }
  .ArrowBack {
    margin-top: 45px;
    transition: 0.1s linear all;
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
  .topMenu {
    display: flex;
    flex-direction: row;
    button {
      @include Button();
      width: 277px;
      margin-left: 32px;
    }
    .reset {
      @include Button2();
      width: 277px;
      margin-left: 32px;
    }
  }
  .search {
    // width: 47vw;
    input {
      display: flex;
      width: 100%;
      border: none;
    }
  }
  .filter {
    display: block;
    position: relative;
    width: 949px;
    img {
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.15s linear all;
      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }
    }
  }
  .container {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
  }
  .notFound {
    margin-top: 100px;
    font-family: "Playfair Display";
    font-size: 38px;
    font-weight: 500;
    line-height: 66px;
  }
  a {
    outline: none;
    text-decoration: none;
    color: #000;
  }

  .loaderMain {
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 300px;
  }
  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: bblFadInOut 1.8s infinite ease-in-out;
  }
  .loader {
    color: var(--blue1);
    font-size: 22px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    top: 0;
  }
  .loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 3.5em;
  }
  @keyframes bblFadInOut {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
}
