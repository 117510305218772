
@font-face {
  font-family: "PlayfairDisplay";
  src: url("./../../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf") format("ttf");
    font-weight: normal;
    font-style: normal;
}


.HomePage {
  overflow: hidden;
  padding-top: 46px;
  width: 100%;
  .HomePage__firstBlock {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 680px;
    z-index: 3;
    position: relative;
  }
  .about {
    margin-bottom: 250px;
    display: flex;
    justify-content: center;

    h3 {
      font-family: Playfair Display;
      font-size: 34px;
      font-weight: 500;
      line-height: 42.5px;
      text-align: left;
    }
    p {
     
      font-size: 20px;
      font-weight: 400;
      line-height: 40px;
      text-align: left;
    }
    h5 {
    
      font-size: 22px;
      font-weight: 600;
      line-height: 44px;
      text-align: left;
    }
    ul li {
     
      font-size: 20px;
      font-weight: 400;
      line-height: 40px;
      text-align: left;
    }
    .about_inner {
      // width: 1200px;
      width: 60%;
      font-size: 20px;
      color: var(--textColor);
      //styleName: контент текст/text;
   
    line-height: 40px;
    text-align: left;

      // @media (max-width: 1850px) {
      //   wi
      // }
    }
  }
  .HomePage__bg1 {
    position: relative;
    top: -55px;
  }
  .BgAboutHomeLeft {
    filter: brightness(0.85);
    position: absolute;
    left: 0;
    top: 1200px;
  }
  .BgAboutHomeRight {
    filter: brightness(0.99);
    position: absolute;
    right: 0;
    top: 1200px;
  }
  .sliderImg2 {
    position: absolute;
    right: 10px;
    top: 245px;
    height: 600px;
  }
  .sliderImg {
    position: absolute;
    right: 70px;
    top: 430px;
    width: 20vw;
    height: auto;
    object-fit: cover;
    @media (max-width: 1550px) {
      top: 440px;
      right: 50px;
    }
    @media (max-width: 1450px) {
      top: 470px;
      right: 50px;
      width: 260px;
    }
  }
}
